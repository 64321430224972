<template>
  <div
    class="landing"
    :class="{ light: theme.themeKey === 'light', dark: theme.themeKey === 'dark' }"
  >
    <!-- toast component -->
    <Toast />
    <!-- vue-router -->
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import axios from "axios";
import { useTheme } from "@/stores/Theme";
import Toast from "@/components/ToastComponent.vue";
import { useRequests } from "@/stores/Requests";
const theme = useTheme();
const requests = useRequests();
// lifecycle hooks
onBeforeMount(() => {

  
  axios.defaults.baseURL = window.location_url ;

  //isSentrelBuild set to true and joyce and dreamage false  == sentrel build
  //joyce and dreamage cant both be true , either one of them with sentrel. 
  //very hackish but needed for now , new configurator + white label configurator is in the pipelines anyway
  //dont want to work on this too much

  //dont forget to change the .ico file in index.html for each build.. it sucks , i want it to be dynamic


  window.isSentrelBuild = true;
  //window.isWhiteLabelBuild = true
 // window.isDreamAgeBuild = true;
  window.isJoyceBuild = true;


//fivestar
if (!window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
  document.title ='five star bath solutions builder';
    }
    //sentrel
    if (window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      document.title ='sentrel builder';
    }
    //joyce
    if (window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      document.title ='joyce builder';
    }
    //dreamage
    if (window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      document.title ='dreamage builder';
    }

     //white label
     if (window.isSentrelBuild && window.isWhiteLabelBuild) {
      document.title ='bathroom builder';
    }

 
  //loads the theme or sets a default if non found
  theme.readTheme();
  
});
</script>

<style>
@import "@/assets/styles/variables.css";
@import "@/assets/styles/quickstyles.css";
@import "@/assets/styles/ui.css";
@import "@/assets/styles/fonts.css";
@import "@/assets/styles/defaultstyles.css";
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-wrap: wrap;
}



.landing {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-wrap: wrap;
}
html {
  height: 100%;
}
body {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  --bs-primary: #44acff;
}
.content-bg-1 {
  background-color: var(--content-bg-1);
}
.content-bg-2 {
  background-color: var(--content-bg-2);
}
.content-bg-3 {
  background-color: var(--content-bg-3);
}
.content-bg-4 {
  background-color: var(--content-bg-4);
}
.content-bg-5 {
  background-color: var(--content-bg-5);
}
.content-bg-6 {
  background-color: var(--content-bg-6);
}

.content-bg-1-inverted{
   background-color: var(--content-bg-1-inverted);
}
.icon-color-1 {
  color: var(--icon-color-1);
}




.button-sidenav:hover {
  background-color: #ffffff2d !important;
 
}
.button-sidenav:focus {
  background-color: #ffffff2d !important;
 
}
.btn-no-focus-shadow {
   
    box-shadow:none !important;
}
.text-align-left {
  text-align: left;
}

.btn-primary {
  color: #fff;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}
.form-control {
  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  background-color: var(--text-input-bg-1) !important;
  color: var(--text-color-2) !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}
.fs-8 {
  font-size: 0.9rem !important;
}
.fs-9 {
  font-size: 0.85rem !important;
}
.fs-10 {
  font-size: 0.8rem !important;
}
.form-label {
  font-size: 0.85rem !important;
}
.icon {
  font-size: 20px;
/* line-height: 24px;*/
}
.content-border-1 {
  border: var(--content-border-1);
}
.content-border-b-1{
  border-bottom:var(--content-border-b-1);
}

.content-header-1 {
  background-color: var(--content-header-bg-1);
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--content-header-color-1);
}

.font-weight-black{
font-weight: 900!important;
}

.color-inherit{
      color: inherit !important
}

.listing-tools-item{
 
  max-width:200px;
}

.icon-c1 {
  color: var(--icon-color-3);
}


.icon-c2 {
 color: var(--icon-color-4);
}


.icon-c3 {
 color: var(--icon-color-5);
}

.icon-ms24{
  max-width:24px;
  max-height:24px;
}




</style>
